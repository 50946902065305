import axios from 'axios'
import store from '../../../store/store'
import timeZones from '../../../constants/_TIME_ZONES.json'

const url = store.state.config.konecta

function getTimeZone() {
  const index = timeZones.findIndex(
    item =>
      item.utc &&
      item.utc.indexOf(Intl.DateTimeFormat().resolvedOptions().timeZone) > -1
  )
  if (index > -1 && timeZones[index]) {
    return timeZones[index].text
  } else {
    const offset = (new Date().getTimezoneOffset() / 60) * -1
    const element = timeZones.find(e => e.offset === offset)
    if (element) {
      return element.text
    }
  }
  return undefined
}

const _getHeaders = () => {
  const session = localStorage.getItem('session')
  const token = JSON.parse(session).token
  return {
    authorization: `Bearer ${token}`,
    'X-Time-Zone': getTimeZone()
  }
}

export default {
  getSurveyArray(surveyId, versionId) {
    return axios({
      method: 'get',
      url:
        url +
        `/survey/${surveyId}/active-survey-array` +
        (versionId ? `?where[version]=${versionId}` : ''),
      headers: _getHeaders()
    })
  },
  getVersionSurveyConfig(versionId, surveyServiceId) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url:
          url +
          `/version/${versionId}/survey-config?select[surveys.config]=true&select[surveys.service]=true&where[surveys.service]=${surveyServiceId}`,
        headers: _getHeaders()
      })
        .then(response => resolve(response))
        .catch(err => {
          if (
            err.response &&
            err.response.status &&
            err.response.status === 404
          ) {
            return resolve({ data: {} })
          }
          reject(err)
        })
    })
  },
  getBotsActiveVersions() {
    return axios({
      method: 'get',
      url: url + `/bot/active-version`,
      headers: _getHeaders()
    })
  },
  getActiveVersionBots(query) {
    return axios({
      method: 'get',
      url: url + `/bot/active-versions` + query,
      headers: _getHeaders()
    })
  },
  getVersionsBots(query='') {
    return axios({
      method: 'get',
      url: url + `/bot/versions${query}`,
      headers: _getHeaders()
    })
  },
  getBotVersion(versionId, query) {
    return axios({
      method: 'get',
      url: url + `/version/${versionId}${query || ''}`,
      headers: _getHeaders()
    })
  },

  getSurveyVersions(serviceId) {
    return axios({
      method: 'get',
      url: url + `/version?where[service]=${serviceId}`,
      headers: _getHeaders()
    })
  },
  getSurvey(surveyId) {
    return axios({
      method: 'get',
      url: url + `/survey/${surveyId}`,
      headers: _getHeaders()
    })
  },
  getSurveys(query) {
    return axios({
      method: 'get',
      url: url + `/survey${query}`,
      headers: _getHeaders()
    })
  },

  getSurveyAudienceItems(params) {
    const { botService, surveyService, surveyVersion, skip, limit } = params
    let path = `/audienceItem/survey-aggregation?where[meta.botService]=${botService}&where[meta.surveyService]=${surveyService}`
    if (surveyVersion) {
      path += `&where[meta.surveyVersion]=${surveyVersion}`
    }
    if (typeof skip === 'number' && typeof limit === 'number') {
      path += `&offset=${skip}&limit=${limit}`
    }
    return axios({
      method: 'get',
      url: url + path,
      headers: _getHeaders()
    })
  },

  getSurveyAudienceCoverage(params) {
    const { botService, surveyService, surveyVersion } = params
    let path = `/audienceItem/survey-covering?where[meta.botService]=${botService}&where[meta.surveyService]=${surveyService}`
    if (surveyVersion) {
      path += `&where[meta.surveyVersion]=${surveyVersion}`
    }
    return axios({
      method: 'get',
      url: url + path,
      headers: _getHeaders()
    })
  },

  getAudienceSchedule(params) {
    const { botId, surveyId } = params
    let path = `/audienceSchedule?where[bot]=${botId}&where[survey]=${surveyId}`
    return axios({
      method: 'get',
      url: url + path,
      headers: _getHeaders()
    })
  },

  getSurveyAudienceExport(params) {
    const {
      botService,
      surveyService,
      surveyVersion,
      showQuestion,
      emailTo
    } = params
    let path = `/audienceItem/survey-export?where[meta.botService]=${botService}&where[meta.surveyService]=${surveyService}`
    if (surveyVersion) {
      path += `&where[meta.surveyVersion]=${surveyVersion}`
    }
    if (showQuestion) {
      path += `&showQuestion=true`
    }
    if (emailTo) {
      path += `&emailTo=${encodeURIComponent(emailTo)}`
    }
    return axios({
      method: 'get',
      url: url + path,
      headers: _getHeaders()
    })
  },

  getSurveyOutgoingRequests(params) {
    const { botService, surveyService } = params
    let path = '/survey-metrics/outgoing-v1/query'
    return axios({
      method: 'post',
      url: url + path,
      data: {
        botService,
        surveyService
      },
      headers: _getHeaders()
    })
  }
}
