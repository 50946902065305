<template>
  <div id="survey-data-view">
    <div class="vx-row">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-breadcrumb class="mb-4" :items="breadcrumb"></vs-breadcrumb>
      </vs-col>
    </div>
    <div class="vx-row">
      <!-- Bots -->
      <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vx-card
          :title="lang.surveyData.bots.title[languageSelected]"
          class="overflow-hidden mb-2"
        >
          <vs-table
            pagination
            max-items="5"
            v-model="selectedBot"
            @selected="handleBotSelected"
            :data="bots"
            sorted
            search
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th sort-key="name">
                {{ lang.surveyData.bots.list.name[languageSelected] }}
              </vs-th>
              <vs-th sort-key="_createdAt">
                {{ lang.surveyData.bots.list.creation[languageSelected] }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>

                <vs-td
                  :data="data[indextr]._createdAt"
                  v-if="data[indextr]._createdAt"
                  >{{ new Date(data[indextr]._createdAt) | date(true) }}</vs-td
                >
                <vs-td v-else>N/A</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
        <div style="height: 10px"></div>
      </vs-col>

      <!-- Surveys -->
      <vs-col v-if="selectedBot" :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <!-- <vs-breadcrumb class="mb-4" :items="breadcrumb"></vs-breadcrumb> -->
        <vx-card
          :title="lang.surveyData.surveys.title[languageSelected]"
          class="overflow-hidden mb-2"
        >
          <!-- :subtitle="lang.surveyData.surveys.subtitle[languageSelected]" -->
          <vs-table
            pagination
            max-items="5"
            v-model="selectedSurvey"
            @selected="handleSurveySelected"
            :data="surveys"
            sorted
            search
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th sort-key="name">
                {{ lang.surveyData.surveys.list.name[languageSelected] }}
              </vs-th>
              <vs-th sort-key="_createdAt">
                {{ lang.surveyData.surveys.list.creation[languageSelected] }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>

                <vs-td :data="data[indextr]._createdAt">{{
                  new Date(data[indextr]._createdAt) | date(true)
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
        <div style="height: 10px"></div>
      </vs-col>

      <!-- Versions -->
      <vs-col v-if="versions" :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
        <vx-card
          :title="lang.surveyData.versions.title[languageSelected]"
          class="overflow-hidden mb-2"
        >
          <!-- :subtitle="lang.surveyData.versions.subtitle[languageSelected]" -->
          <vs-table
            pagination
            max-items="5"
            v-model="selectedVersion"
            @selected="handleVersionSelected"
            :data="versions"
            sorted
            search
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th>
                {{ lang.surveyData.versions.list.tag[languageSelected] }}
              </vs-th>
              <vs-th sort-key="status">
                {{ lang.surveyData.versions.list.status[languageSelected] }}
              </vs-th>
              <vs-th sort-key="_createdAt">
                {{ lang.surveyData.versions.list.creation[languageSelected] }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].tag">{{ data[indextr].tag }}</vs-td>

                <vs-td :data="data[indextr].status">
                  <vs-chip
                    :color="
                      data[indextr].status === 'production' ? 'success' : ''
                    "
                  >
                    {{
                      data[indextr].status === 'production'
                        ? `${lang.surveyData.versions.status.prod[languageSelected]}`
                        : `${lang.surveyData.versions.status.dev[languageSelected]}`
                    }}
                  </vs-chip>
                </vs-td>

                <vs-td :data="data[indextr]._createdAt">{{
                  new Date(data[indextr]._createdAt) | date(true)
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
        <div style="height: 10px"></div>
      </vs-col>

      <!-- Covering -->
      <vs-col
        v-if="versions && covering !== null"
        :vs-lg="'6'"
        :vs-xs="'12'"
        :vs-sm="'12'"
      >
        <vx-card
          :title="lang.surveyData.covering.title[languageSelected]"
          :subtitle="lang.surveyData.covering.subtitle[languageSelected]"
          class="overflow-hidden mb-2"
        >
          <!-- CHART -->
          <template slot="no-body">
            <div class="mt-10">
              <vue-apex-charts
                type="radialBar"
                height="240"
                :options="chartOptions"
                :series="[covering]"
              />
            </div>
          </template>

          <!-- DATA -->
          <div class="flex justify-between text-center" slot="no-body-bottom">
            <div
              class="
                w-1/2
                border
                border-solid
                border-grey-light
                border-r-0
                border-b-0
                border-l-0
              "
            >
              <p class="mt-4">
                {{ lang.surveyData.covering.list.completed[languageSelected] }}
              </p>
              <p class="mb-4 text-3xl font-semibold">{{ completado }}</p>
            </div>
            <div
              class="
                w-1/2
                border border-solid border-grey-light border-r-0 border-b-0
              "
            >
              <p class="mt-4">
                {{
                  lang.surveyData.covering.list.notCompleted[languageSelected]
                }}
              </p>
              <p class="mb-4 text-3xl font-semibold">{{ no_completado }}</p>
            </div>
          </div>
        </vx-card>
        <div style="height: 10px"></div>
      </vs-col>

      <vs-col
        v-if="selectedSurvey && versions"
        :vs-lg="'12'"
        :vs-xs="'12'"
        :vs-sm="'12'"
      >
        <vx-card
          v-show="selectedSurveyData"
          id="ag-grid-card"
          :title="lang.surveyData.results.title[languageSelected]"
          :subtitle="lang.surveyData.results.subtitle[languageSelected]"
          class="overflow-hidden"
        >
          <div class="vx-row mt-6">
            <vs-col :vs-lg="'6'" :vs-xs="'6'" :vs-sm="'6'">
              <div>
                <label>
                  {{ lang.surveyData.results.form.switch[languageSelected] }}
                </label>
                <vx-slider v-model="showQuestion" />
              </div>
            </vs-col>
            <vs-col :vs-lg="'6'" :vs-xs="'6'" :vs-sm="'6'">
              <div
                class="flex flex-wrap justify-between items-center float-right"
              >
                <div
                  class="
                    flex flex-wrap
                    items-center
                    justify-between
                    ag-grid-table-actions-right
                    float-right
                  "
                >
                  <!-- <vs-input
                    class="mb-4 md:mb-0 mr-4"
                    v-model="searchQuery"
                    @input="updateSearchQuery"
                    :placeholder="
                      lang.surveyData.results.form.search[languageSelected]
                    "
                  />-->
                  <vs-button
                    color="primary"
                    type="flat"
                    icon="icon-refresh-cw"
                    icon-pack="feather"
                    class="mb-4 md:mb-0"
                    @click="refreshData()"
                    >{{
                      lang.surveyData.results.form.refresh[languageSelected]
                    }}</vs-button
                  >
                  <vs-prompt
                    :vs-is-valid="!invalidEmail"
                    :vs-title="
                      lang.surveyData.results.form.exportCSV[languageSelected]
                    "
                    @vs-cancel="exportEmail = ''"
                    @vs-accept="exportDataAsCsv"
                    :vs-accept-text="
                      lang.surveyData.results.form.exportCSVPrompt.accept[
                        languageSelected
                      ]
                    "
                    :vs-cancel-text="
                      lang.surveyData.results.form.exportCSVPrompt.cancel[
                        languageSelected
                      ]
                    "
                    :vs-active.sync="exportPrompt"
                  >
                    <div class="con-exemple-prompt">
                      {{
                        lang.surveyData.results.form.exportCSVPrompt.text[
                          languageSelected
                        ]
                      }}
                      <vs-input
                        class="w-full mt-2"
                        placeholder="Code"
                        v-model="exportEmail"
                        :danger="invalidEmail"
                        :danger-text="
                          lang.surveyData.results.form.exportCSVPrompt
                            .invalidEmail[languageSelected]
                        "
                      />
                    </div>
                  </vs-prompt>
                  <vs-button
                    color="primary"
                    type="flat"
                    icon="icon-download"
                    icon-pack="feather"
                    class="mb-4 md:mb-0"
                    @click="showExportPrompt()"
                    :disabled="downloadDisabled"
                  >
                    {{
                      lang.surveyData.results.form.exportCSV[languageSelected]
                    }}
                  </vs-button>

                  <vs-button
                    color="primary"
                    type="flat"
                    icon="icon-arrow-up-right"
                    icon-pack="feather"
                    class="mb-4 md:mb-0"
                    @click="exportOutgoingRequests()"
                    :disabled="downloadDisabled"
                  >
                    {{
                      lang.surveyData.results.form.outgoingRequests[
                        languageSelected
                      ]
                    }}
                  </vs-button>
                </div>
              </div>
            </vs-col>
          </div>

          <ag-grid-vue
            v-if="myData.length > 0"
            id="button-with-loading"
            class="
              ag-theme-material
              w-200
              h-800
              my-4
              ag-grid-table
              vs-con-loading__container
            "
            :gridOptions="gridOptions"
            :columnDefs="columnDefsCurrent"
            :defaultColDef="defaultColDef"
            :rowData="myData"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :suppressPaginationPanel="false"
            style="height: 600px"
          ></ag-grid-vue>
          <div class="flex flex-wrap justify-between items-center float-right">
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
                float-right
                mb-base
              "
            >
              <vs-button
                v-if="total !== myData.length"
                color="primary"
                type="flat"
                icon="icon-plus"
                icon-pack="feather"
                class="mb-4 md:mb-0"
                @click="getMore"
              >
                {{
                  lang.surveyData.results.form.showMore.yes[languageSelected]
                }}
                ({{ total - myData.length }})
              </vs-button>
              <vs-button
                v-else
                color="primary"
                type="flat"
                class="mb-4 md:mb-0"
                :disabled="true"
                @click="getMore"
              >
                {{
                  lang.surveyData.results.form.showMore.not[languageSelected]
                }}
              </vs-button>
            </div>
          </div>
        </vx-card>
      </vs-col>

      <!-- Audience Schedules -->
      <vs-col
        v-if="audienceSchedules && audienceSchedules.length > 0"
        :vs-lg="'6'"
        :vs-xs="'12'"
        :vs-sm="'12'"
      >
        <vx-card
          :title="lang.surveyData.audiences.title[languageSelected]"
          :subtitle="lang.surveyData.audiences.subtitle[languageSelected]"
          class="overflow-hidden mb-2 mt-6"
        >
          <vs-table
            class="mt-base"
            pagination
            max-items="5"
            :data="audienceSchedules"
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th>
                {{ lang.surveyData.audiences.table.progress[languageSelected] }}
              </vs-th>
              <vs-th sort-key="platform">
                {{ lang.surveyData.audiences.table.channel[languageSelected] }}
              </vs-th>
              <vs-th sort-key="schedule">
                {{ lang.surveyData.audiences.table.schedule[languageSelected] }}
              </vs-th>
              <vs-th sort-key="_updatedAt">
                {{ lang.surveyData.audiences.table.updated[languageSelected] }}
              </vs-th>
              <vs-th sort-key="_createdAt">
                {{ lang.surveyData.audiences.table.created[languageSelected] }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].progress">
                  <vs-chip
                    :color="data[indextr].completed ? 'success' : 'warning'"
                    >{{ data[indextr].progress }}</vs-chip
                  >
                </vs-td>

                <vs-td :data="data[indextr].platform">
                  <vs-td :data="data[indextr].platform">{{
                    data[indextr].params1.platform
                  }}</vs-td>
                </vs-td>

                <vs-td :data="data[indextr].schedule">{{
                  new Date(data[indextr].schedule) | date(true)
                }}</vs-td>

                <vs-td :data="data[indextr]._updatedAt">{{
                  new Date(data[indextr]._updatedAt) | date(true)
                }}</vs-td>

                <vs-td :data="data[indextr]._createdAt">{{
                  new Date(data[indextr]._createdAt) | date(true)
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
        <div style="height: 10px"></div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { AgGridVue } from 'ag-grid-vue'
import VueApexCharts from 'vue-apexcharts'
import { sortByDate } from '../../../utils'

import Validations from '@/store/Validations'

const Json2csvParser = require('json2csv').Parser

import services from './services'
import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'
const moment = require('moment')

export default {
  components: {
    AgGridVue,
    VueApexCharts
  },
  data() {
    return {
      audienceSchedules: [],

      Json2csvParser,

      exportPrompt: false,
      exportEmail: '',
      invalidEmail: false,

      showMore: true,
      paginationSize: 10,
      total: null,
      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 110,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%'
            },
            track: {
              background: '#bfc5cc',
              strokeWidth: '50%'
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: 18,
                color: '#99a2ac',
                fontSize: '4rem'
              }
            }
          }
        },
        colors: ['#00db89'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#00b5b5'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        chart: {
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      },

      breadcrumb: [
        {
          title: 'Encuestas',
          url: '/#/surveys'
          // active: true,
        },
        {
          title: 'Analítica',
          url: '/#/surveys-data',
          active: true
        }
      ],

      bots: [],
      selectedBot: null,
      selectedBotSurveysConfig: null,

      surveys: [],
      selectedSurvey: null,
      selectedSurveyData: null,

      versions: null,
      preSelectedVersion: null,
      selectedVersion: null,

      searchQuery: '',
      gridOptions: {
        enableCellTextSelection: true
      },
      // localeText: null,
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [],
      columnDefsCurrent: [],
      showQuestion: false,

      myData: [],
      allData: null,

      completado: null,
      no_completado: null,
      covering: null,

      getAudienceResult: [],

      downloadDisabled: false
    }
  },
  watch: {
    exportEmail(val) {
      this.invalidEmail = !Validations.isValidEmail(val)
    },
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4
        this.gridOptions.columnApi.setColumnPinned('email', null)
      } else this.gridOptions.columnApi.setColumnPinned('email', 'left')
    },
    // eslint-disable-next-line no-unused-vars
    showQuestion(val) {
      this.getSimpleUserData(
        this.selectedVersion ? this.selectedVersion._id : undefined
      )
    },
    breadcrumbJSON() {
      this.$nextTick(() => {
        const aux = document.getElementsByClassName('vs-breadcrumb--link')
        for (let item of aux) {
          const newElement = item.cloneNode(true)
          item.parentNode.replaceChild(newElement, item)
          newElement.addEventListener('click', () => {
            this.$router.replace('/' + newElement.hash)
          })
        }
      })
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    breadcrumbJSON() {
      return JSON.stringify(this.breadcrumb)
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 50
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    showExportPrompt() {
      this.exportPrompt = true
    },
    async exportDataAsCsv() {
      // function saveData(blob, fileName) {
      //   var a = document.createElement('a')
      //   document.body.appendChild(a)
      //   a.style = 'display: none'
      //   var url = window.URL.createObjectURL(blob)
      //   a.href = url
      //   a.download = fileName
      //   a.click()
      //   window.URL.revokeObjectURL(url)
      // }
      const params = {
        botService: this.selectedBot.service,
        surveyService: this.selectedSurvey.service,
        showQuestion: this.showQuestion,
        emailTo: this.exportEmail
      }
      this.$vs.loading({
        container: '#button-with-loading',
        scale: 1
      })
      this.downloadDisabled = true
      try {
        await services.getSurveyAudienceExport(params)
        this.$notify.success(
          this.lang.surveyData.results.form.exportCSVPrompt.sucess.title[
            this.languageSelected
          ],
          this.lang.surveyData.results.form.exportCSVPrompt.sucess.text[
            this.languageSelected
          ]
        )
      } catch (ex) {
        this.$notify.error(
          this.lang.surveyData.errors.generic.title[this.languageSelected],
          this.lang.surveyData.errors.generic.text[this.languageSelected]
        )
      }
      // let filename = 'konecta_survey_export_'
      // filename += new Date().toISOString()

      // var BOM = '\uFEFF'
      // var csvData = BOM + result.data

      // var blob = new Blob([csvData], {
      //   type: 'text/plain; encoding=utf-8'
      // })

      // saveData(blob, `${filename}.csv`)
      this.downloadDisabled = false
      this.$vs.loading.close('#button-with-loading > .con-vs-loading')
    },
    async exportOutgoingRequests() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }
      const params = {
        botService: this.selectedBot.service,
        surveyService: this.selectedSurvey.service,
        showQuestion: this.showQuestion
      }
      this.$vs.loading({
        container: '#button-with-loading',
        scale: 1
      })
      this.downloadDisabled = true
      const result = await services.getSurveyOutgoingRequests(params)
      let filename = 'konecta_outgoing_requests_'
      filename += new Date().toISOString()

      if (!result.data || !result.data[0] || !result.data[0][0]) {
        this.downloadDisabled = false
        this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        return this.$vs.notify({
          title: this.lang.errors.title[this.languageSelected],
          text: this.lang.general.noDataText[this.languageSelected],
          color: 'warning',
          position: 'bottom-right'
        })
      }

      const fields = Object.keys(result.data[0][0])

      const json2csvParser = new Json2csvParser({ fields })
      const csv = json2csvParser.parse(result.data[0])

      var BOM = '\uFEFF'
      var csvData = BOM + csv

      var blob = new Blob([csvData], {
        type: 'text/plain; encoding=utf-8'
      })

      saveData(blob, `${filename}.csv`)
      this.downloadDisabled = false
      this.$vs.loading.close('#button-with-loading > .con-vs-loading')
    },
    refreshData() {
      this.handleSurveySelected(this.selectedSurvey)
    },
    async getMore() {
      this.$vs.loading({
        container: '#button-with-loading',
        scale: 1
      })
      await this.getSurvey(
        this.selectedSurvey,
        this.myData.length,
        this.paginationSize,
        false
      )
      this.$vs.loading.close('#button-with-loading > .con-vs-loading')
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    async handleVersionSelected(tr, update_breadcrumb) {
      if (
        this.preSelectedVersion &&
        this.preSelectedVersion._id === this.selectedVersion._id
      ) {
        this.selectedVersion = null
        this.preSelectedVersion = null
        await this.getSimpleUserData()
        this.breadcrumb = [
          {
            title: this.lang.surveyData.breadcrumb.surveys[
              this.languageSelected
            ],
            url: '/#/surveys'
            // active: true,
          },
          {
            title: this.lang.surveyData.breadcrumb.surveyData[
              this.languageSelected
            ],
            url: '/#/surveys-data'
            // active: true,
          },
          {
            title: this.selectedBot.name,
            url: `/#/surveys-data?bot=${this.selectedBot._id}`,
            active: false
          },
          {
            title: this.selectedSurvey.name,
            url: `/#/surveys-data?bot=${this.selectedBot._id}&survey=${this.selectedSurvey._id}`,
            active: true
          }
        ]
        if (update_breadcrumb === false) {
          return
        }
        this.$router.push({
          query: { bot: this.selectedBot._id, survey: this.selectedSurvey._id }
        })
      } else {
        this.preSelectedVersion = this.selectedVersion
        await this.getSimpleUserData(this.selectedVersion._id)
        this.breadcrumb = [
          {
            title: this.lang.surveyData.breadcrumb.surveys[
              this.languageSelected
            ],
            url: '/#/surveys'
            // active: true,
          },
          {
            title: this.lang.surveyData.breadcrumb.surveyData[
              this.languageSelected
            ],
            url: '/#/surveys-data'
            // active: true,
          },
          {
            title: this.selectedBot.name,
            url: `/#/surveys-data?bot=${this.selectedBot._id}`,
            active: false
          },
          {
            title: this.selectedSurvey.name,
            url: `/#/surveys-data?bot=${this.selectedBot._id}&survey=${this.selectedSurvey._id}`,
            active: false
          },
          {
            title: tr.tag,
            url: `/#/surveys-data?bot=${this.selectedBot._id}&survey=${this.selectedSurvey._id}&version=${tr._id}`,
            active: true
          }
        ]
        if (update_breadcrumb === false) {
          return
        }
        this.$router.push({
          query: {
            bot: this.selectedBot._id,
            survey: this.selectedSurvey._id,
            version: this.selectedVersion._id
          }
        })
      }
    },
    async handleSurveySelected(tr, update_breadcrumb) {
      if (update_breadcrumb !== false) {
        this.$vs.loading()
      }
      this.selectedVersion = null
      this.versions = null
      // console.log('tr', JSON.stringify(tr, undefined, 2))
      const _config = await services.getVersionSurveyConfig(
        this.selectedBot.version,
        tr.service
      )
      this.selectedBotSurveysConfig = _config.data

      // eslint-disable-next-line no-unused-vars
      let r
      try {
        // eslint-disable-next-line no-unused-vars
        r = await services.getSurveyArray(tr._id)
      } catch (ex) {
        if (ex && ex.response && ex.response.status === 404) {
          this.$vs.notify({
            title: this.lang.surveyData.errors.noSurveysProd.title[
              this.languageSelected
            ],
            text: this.lang.surveyData.errors.noSurveysProd.text[
              this.languageSelected
            ],
            color: 'warning',
            position: 'top-right'
          })
        } else {
          this.$vs.notify({
            title: this.lang.surveyData.errors.generic.title[
              this.languageSelected
            ],
            text: this.lang.surveyData.errors.generic.text[
              this.languageSelected
            ],
            color: 'danger',
            position: 'top-right'
          })
        }
        this.$vs.loading.close()
        return
      }

      await this.getSurveyVersions(tr.service)
      await this.getSurvey(tr, 0, this.paginationSize, true)
      this.breadcrumb = [
        {
          title: this.lang.surveyData.breadcrumb.surveys[this.languageSelected],
          url: '/#/surveys'
          // active: true,
        },
        {
          title: this.lang.surveyData.breadcrumb.surveyData[
            this.languageSelected
          ],
          url: '/#/surveys-data'
          // active: true,
        },
        {
          title: this.selectedBot.name,
          url: `/#/surveys-data?bot=${this.selectedBot._id}`,
          active: false
        },
        {
          title: tr.name,
          url: `/#/surveys-data?bot=${this.selectedBot._id}&survey=${tr._id}`,
          active: true
        }
      ]
      this.gridApi = this.gridOptions.api
      this.$vs.loading.close()
      window.scrollTo(0, document.body.scrollHeight)
      if (update_breadcrumb === false) {
        return
      }
      this.$router.push({
        query: { bot: this.selectedBot._id, survey: tr._id }
      })

      if (
        this.selectedSurvey &&
        this.selectedSurvey._id &&
        this.selectedBot &&
        this.selectedBot._id
      ) {
        this.joinScheduleSurveyProgress(
          this.selectedSurvey._id,
          this.selectedBot._id
        )
      } else {
        this.leaveScheduleSurveyProgress()
      }
    },
    async handleBotSelected(tr, update_breadcrumb) {
      try {
        if (update_breadcrumb !== false) {
          this.$vs.loading()
        }
        this.selectedBot = null
        this.selectedSurvey = null
        this.surveys = null
        this.selectedVersion = null
        this.versions = null

        const result = await services.getSurveys(`?select[data]=false`)
        this.surveys = result.data.map(element => ({
          _id: element._id,
          service: element.service._id,
          name: element.service.name,
          _createdAt: element._createdAt
        }))
        this.surveys = sortByDate(this.surveys, '_createdAt', 'desc')

        this.selectedBot = tr
        this.breadcrumb = [
          {
            title: this.lang.surveyData.breadcrumb.surveys[
              this.languageSelected
            ],
            url: '/#/surveys'
            // active: true,
          },
          {
            title: this.lang.surveyData.breadcrumb.surveyData[
              this.languageSelected
            ],
            url: '/#/surveys-data'
            // active: true,
          },
          {
            title: this.selectedBot.name,
            url: `/#/surveys-data?bot=${this.selectedBot._id}`,
            active: true
          }
        ]
        if (update_breadcrumb === false) {
          return
        }
        this.$router.push({ query: { bot: tr._id } })
      } catch (error) {
        this.$vs.notify({
          title: this.lang.surveyData.errors.generic.title[
            this.languageSelected
          ],
          text: this.lang.surveyData.errors.generic.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      }
      this.$vs.loading.close()

      if (
        this.selectedSurvey &&
        this.selectedSurvey._id &&
        this.selectedBot &&
        this.selectedBot._id
      ) {
        this.joinScheduleSurveyProgress(
          this.selectedSurvey._id,
          this.selectedBot._id
        )
      } else {
        this.leaveScheduleSurveyProgress()
      }
    },

    getVersionData(versionId) {
      const userDataVersionFiltered = {}
      Object.keys(this.allData).forEach(user => {
        userDataVersionFiltered[user] = {}
        Object.keys(this.allData[user]).forEach(field => {
          if (!versionId) {
            userDataVersionFiltered[user][field] = this.allData[user][field]
          } else {
            userDataVersionFiltered[user][field] = this.allData[user][
              field
            ].filter(element => element.version && element.version == versionId)
          }
          if (
            field === 'finalizado' &&
            userDataVersionFiltered[user][field].length === 0
          ) {
            userDataVersionFiltered[user][field] = [
              {
                value: 'N/A',
                _createdAt: new Date()
              }
            ]
          }
        })
      })
      return userDataVersionFiltered
    },
    getSimpleUserData(versionId) {
      const userDataVersionFiltered = this.getVersionData(versionId)
      // console.log('userDataVersionFiltered', JSON.stringify(userDataVersionFiltered, undefined, 2))
      const simpleUserData = {}
      Object.keys(userDataVersionFiltered).forEach(user => {
        simpleUserData[user] = {}
        Object.keys(userDataVersionFiltered[user]).forEach(field => {
          if (
            userDataVersionFiltered[user][field] &&
            userDataVersionFiltered[user][field].length > 0 &&
            (field.substr(0, 3) !== '?) ' || this.showQuestion)
          ) {
            simpleUserData[user][field] =
              userDataVersionFiltered[user][field][
                userDataVersionFiltered[user][field].length - 1
              ].value
          }
        })
      })
      // this.completado = 0
      this.myData = []
      // console.log(simpleUserData)
      Object.keys(simpleUserData).forEach(user => {
        this.myData.push({
          senderId: user
        })
        Object.keys(simpleUserData[user]).forEach(field => {
          this.myData[this.myData.length - 1][field] =
            simpleUserData[user][field]
        })
        // if (this.myData[this.myData.length - 1].finalizado !== 'N/A') {
        //   this.completado += 1
        // }
      })
      // this.myData.forEach((element) => {
      //   if (element.finalizado && element.finalizado !== 'N/A') {
      //     this.completado += 1;
      //   }
      // });
      // this.no_completado = this.myData.length - this.completado
      // this.covering = parseInt((this.completado / this.myData.length) * 100)
      // console.log('se llama esto')

      if (!this.showQuestion) {
        this.columnDefsCurrent = JSON.parse(
          JSON.stringify(this.columnDefs)
        ).filter(element => !element.question)
      } else {
        this.columnDefsCurrent = JSON.parse(JSON.stringify(this.columnDefs))
      }
    },

    async getSurveyVersions(serviceId) {
      // TODO: PAGINADO.
      try {
        const result = await services.getSurveyVersions(serviceId)
        this.versions = result.data.map(element => ({
          _id: element._id,
          active: element.active,
          service: element.service._id,
          tag: element.tag || 'N/A',
          status: element.active ? 'production' : 'development',
          log: element.log,
          _createdAt: element._createdAt,
          _updatedAt: element._updatedAt
        }))
        this.versions = sortByDate(this.versions, '_updatedAt', 'desc')
      } catch (error) {
        this.$vs.notify({
          title: this.lang.surveyData.errors.generic.title[
            this.languageSelected
          ],
          text: this.lang.surveyData.errors.generic.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async getCovering(survey) {
      const params = {
        botService: this.selectedBot.service,
        surveyService: survey.service
      }

      const result = await services.getSurveyAudienceCoverage(params)

      this.covering =
        result.data.length > 0
          ? parseInt((result.data.finished / result.data.length) * 100)
          : 0
      this.completado = result.data.finished
      this.no_completado = result.data.length - this.completado
    },
    async getSurvey(survey, skip, limit, clear) {
      function questionsToString(questions) {
        let result = ''
        ;(questions || []).forEach(element => {
          if (result.length === 0) {
            result += element.responseText
          } else {
            result += `, ${element.responseText}`
          }
          if (element.responseOptions) {
            result += ` [${element.responseOptions
              .map(item => item.text)
              .join('|')}]`
          }
        })
        return result
      }

      try {
        const params = {
          botService: this.selectedBot.service,
          surveyService: survey.service,
          skip,
          limit
        }

        if (clear) {
          this.getAudienceResult = []
          this.getCovering(survey)
        }

        const result = await services.getSurveyAudienceItems(params)

        this.getAudienceResult = this.getAudienceResult.concat(
          result.data.result
        )

        if (clear || !this.selectedSurveyData) {
          this.selectedSurveyData = result.data.result.reduce(
            (acc, elem) => acc.concat(elem.data || []),
            []
          )
        } else {
          this.selectedSurveyData = this.selectedSurveyData.concat(
            result.data.result.reduce(
              (acc, elem) => acc.concat(elem.data || []),
              []
            )
          )
        }

        this.total = result.data.length

        const keys = []
        const numericKeys = {} // para saber si es numérico
        const userData = {}
        const simpleUserData = {}

        this.getAudienceResult.forEach(element => {
          userData[element.senderId] = {}
          simpleUserData[element.senderId] = {}
          if (element.fixedId) {
            // console.log('element fixedId', element.fixedId)
            userData[element.senderId].fixedId = [element.fixedId]
            simpleUserData[element.senderId].fixedId = [element.fixedId]
          } else {
            // console.log('nope')
          }
        })
        numericKeys['inicializado'] = false
        this.selectedSurveyData.forEach(element => {
          const elementKey = element.key.toString().replace(/\./g, '_')
          if (keys.indexOf(elementKey) === -1) {
            keys.push(elementKey)
            numericKeys[elementKey] = null
          }
          element.values.forEach(item => {
            if (!userData[item.meta.sender]) {
              userData[item.meta.sender] = {}
              simpleUserData[item.meta.sender] = {}
            }
            // TODO: Sonar: refactor
            if (!simpleUserData[item.meta.sender]['inicializado']) {
              simpleUserData[item.meta.sender]['inicializado'] = moment(
                new Date(item._createdAt)
              ).format('YYYY-MM-DD, HH:mm')
            } else if (
              simpleUserData[item.meta.sender]['inicializado'] >
              moment(new Date(item._createdAt)).format('YYYY-MM-DD, HH:mm')
            ) {
              simpleUserData[item.meta.sender]['inicializado'] = moment(
                new Date(item._createdAt)
              ).format('YYYY-MM-DD, HH:mm')
            }
            if (!userData[item.meta.sender]['inicializado']) {
              userData[item.meta.sender]['inicializado'] = []
              userData[item.meta.sender]['inicializado'].push({
                value: moment(new Date(item._createdAt)).format(
                  'YYYY-MM-DD, HH:mm'
                ),
                version: item.meta.survey.version,
                _createdAt: item._createdAt
              })
            } else {
              let index = userData[item.meta.sender]['inicializado'].findIndex(
                i => i.version == item.meta.survey.version
              )
              if (index == -1) {
                userData[item.meta.sender]['inicializado'].push({
                  value: moment(new Date(item._createdAt)).format(
                    'YYYY-MM-DD, HH:mm'
                  ),
                  version: item.meta.survey.version,
                  _createdAt: item._createdAt
                })
              } else if (
                userData[item.meta.sender]['inicializado'][index].value >
                moment(new Date(item._createdAt)).format('YYYY-MM-DD, HH:mm')
              ) {
                userData[item.meta.sender]['inicializado'][
                  index
                ].value = moment(new Date(item._createdAt)).format(
                  'YYYY-MM-DD, HH:mm'
                )
                userData[item.meta.sender]['inicializado'][index]._createdAt =
                  item._createdAt
              }
            }

            if (!userData[item.meta.sender][elementKey]) {
              if (elementKey === '_event finish survey') {
                userData[item.meta.sender]['finalizado'] = []
              } else {
                userData[item.meta.sender][elementKey] = []
                userData[item.meta.sender][`?) ${elementKey}`] = []
              }
            }
            if (elementKey === '_event finish survey') {
              userData[item.meta.sender]['finalizado'].push({
                value: item.value,
                version: item.meta.survey.version,
                _createdAt: item._createdAt
              })
            } else {
              if (item.meta.question) {
                userData[item.meta.sender][`?) ${elementKey}`].push({
                  value: questionsToString(item.meta.question),
                  version: item.meta.survey.version,
                  _createdAt: item._createdAt
                })
              }
              userData[item.meta.sender][elementKey].push({
                value: item.value,
                version: item.meta.survey.version,
                _createdAt: item._createdAt
              })
            }
            simpleUserData[item.meta.sender][
              `?) ${elementKey}`
            ] = questionsToString(item.meta.question)
            simpleUserData[item.meta.sender][elementKey] = item.value
            if (numericKeys[elementKey] === null) {
              if (/^\d+$/.test(item.value)) {
                numericKeys[elementKey] = true
              } else {
                numericKeys[elementKey] = false
              }
            } else if (numericKeys[elementKey] === true) {
              if (!/^\d+$/.test(item.value)) {
                numericKeys[elementKey] = false
              }
            }
          })
        })

        let columnDefsFiltred = [
          {
            headerName: this.lang.surveyData.results.form.table.user[
              this.languageSelected
            ],
            field: 'senderId',
            filter: true,
            pinned: 'left'
          },
          {
            headerName: this.lang.surveyData.results.form.table.firstMessage[
              this.languageSelected
            ],
            field: 'inicializado',
            filter: true,
            pinned: 'left'
          },
          {
            headerName: this.lang.surveyData.results.form.table.ended[
              this.languageSelected
            ],
            field: 'finalizado',
            filter: true,
            pinned: 'left'
          }
        ]

        columnDefsFiltred = columnDefsFiltred.concat(
          keys
            .filter(key => key !== '_event finish survey')
            .map(key => ({
              headerName: key,
              field: key,
              filter: true,
              editable: false
            }))
        )
        this.columnDefs = []
        for (let i in columnDefsFiltred) {
          columnDefsFiltred[i].editable = false
          this.columnDefs.push(columnDefsFiltred[i])
          if (!columnDefsFiltred[i].pinned) {
            this.columnDefs.push({
              headerName: `?) ${columnDefsFiltred[i].headerName}`,
              field: `?) ${columnDefsFiltred[i].headerName}`,
              filter: true,
              question: true,
              editable: false
            })
          }
        }

        this.columnDefs.forEach((element, index) => {
          if (index === 0) {
            return // es el senderId
          }
          const key = Object.keys(numericKeys)[index - 1]
          if (numericKeys[key]) {
            element.filter = 'agNumberColumnFilter'
          }
        })
        this.myData = []
        Object.keys(simpleUserData).forEach(key => {
          this.myData.push({
            // senderId: key
            senderId:
              simpleUserData[key].fixedId && simpleUserData[key].fixedId[0]
                ? simpleUserData[key].fixedId[0]
                : key
          })
          Object.keys(simpleUserData[key]).forEach(key2 => {
            if (key2 === '_event finish survey') {
              this.myData[this.myData.length - 1]['finalizado'] =
                simpleUserData[key][key2]
            } else {
              this.myData[this.myData.length - 1][key2] =
                simpleUserData[key][key2]
            }
          })
          if (!this.myData[this.myData.length - 1]['finalizado']) {
            this.myData[this.myData.length - 1]['finalizado'] = 'N/A'
          }
          if (!this.myData[this.myData.length - 1]['inicializado']) {
            this.myData[this.myData.length - 1]['inicializado'] = 'N/A'
          }
        })
        this.allData = userData
        if (
          this.selectedBotSurveysConfig &&
          this.selectedBotSurveysConfig['k-base'] &&
          this.selectedBotSurveysConfig['k-base'].audience &&
          this.selectedBotSurveysConfig['k-base'].audience.length > 0
        ) {
          this.selectedBotSurveysConfig['k-base'].audience.forEach(element => {
            if (!this.allData[element.user]) {
              const aux_date = new Date()
              this.allData[element.user] = {
                finalizado: [
                  {
                    value: 'N/A',
                    _createdAt: aux_date
                  }
                ]
              }
              this.myData.push({
                senderId: element.user,
                finalizado: 'N/A'
              })
            }
          })
        }
        // this.completado = 0
        // this.myData.forEach(element => {
        //   if (element.finalizado && element.finalizado !== 'N/A') {
        //     this.completado += 1
        //   }
        // })
        // this.no_completado = this.myData.length - this.completado
        // this.covering = parseInt(
        //   (this.completado / this.myData.length) * 100
        // )
        // this.getSimpleUserData(
        //   this.selectedVersion ? this.selectedVersion._id : undefined
        // )

        // this.columnDefsCurrent = JSON.parse(JSON.stringify(this.columnDefs))

        if (!this.showQuestion) {
          this.columnDefsCurrent = JSON.parse(
            JSON.stringify(this.columnDefs)
          ).filter(element => !element.question)
        } else {
          this.columnDefsCurrent = JSON.parse(JSON.stringify(this.columnDefs))
        }
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          title: this.lang.surveyData.errors.generic.title[
            this.languageSelected
          ],
          text: this.lang.surveyData.errors.generic.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async getSurveys() {
      // TODO: PAGINADO.
      try {
        const result = await services.getSurveys('?select[data]=false')
        this.surveys = result.data.map(element => ({
          _id: element._id,
          service: element.service._id,
          name: element.service.name,
          _createdAt: element._createdAt
        }))
      } catch (error) {
        this.$vs.notify({
          title: this.lang.surveyData.errors.generic.title[
            this.languageSelected
          ],
          text: this.lang.surveyData.errors.generic.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      }
    },

    async getBots() {
      try {
        const result = await services.getBotsActiveVersions()
        // console.log('result data', JSON.stringify(result.data, undefined, 2))
        this.bots = result.data.map(element => ({
          _id: element._id,
          service: element.service ? element.service._id : 'dale',
          name: element.service.name,
          version: element.version ? element.version._id : 'no_version',
          _createdAt: element._createdAt
        }))
        this.bots = sortByDate(this.bots, '_createdAt', 'desc') // this.bots.sort(_createdAtDec)
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          title: this.lang.surveyData.errors.generic.title[
            this.languageSelected
          ],
          text: this.lang.surveyData.errors.generic.text[this.languageSelected],
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async joinScheduleSurveyProgress(surveyId, botId) {
      this.$socket.client.emit('joinScheduleSurveyProgress', {
        surveyId,
        botId
      })
      const { data } = await services.getAudienceSchedule({ botId, surveyId })
      data.result.forEach(item => {
        item.platform = item.params1.platform
        item.schedule = item.params2.schedule
      })
      this.audienceSchedules = data.result
    },
    leaveScheduleSurveyProgress() {
      this.$socket.client.emit('leaveScheduleSurveyProgress', {})
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api

    this.$socket.client.on('scheduleSurveyProgress', data => {
      this.$log.info('scheduleSurveyProgress', data)
      const index = this.audienceSchedules.findIndex(
        item => item._id === data.audienceScheduleId
      )
      this.audienceSchedules[index].progress = data.progress
      this.audienceSchedules[index]._updatedAt = new Date()
      if (data.completed) {
        this.audienceSchedules[index].completed = true
      }
    })
  },
  beforeDestroy() {
    this.$socket.client.off('scheduleSurveyProgress')
  },
  async created() {
    this.breadcrumb = [
      {
        title: this.lang.surveyData.breadcrumb.surveys[this.languageSelected],
        url: '/#/surveys'
        // active: true,
      },
      {
        title: this.lang.surveyData.breadcrumb.surveyData[
          this.languageSelected
        ],
        url: '/#/surveys-data',
        active: true
      }
    ]
    this.$vs.loading()
    await this.getBots()

    // await this.getSurveys();

    if (this.$route.query.bot) {
      let i = null
      this.bots.some((bot, index) => {
        if (bot._id.toString() === this.$route.query.bot) {
          i = index
          return true
        }
      })
      if (i !== null) {
        this.selectedBot = this.bots[i]
        const aux_survey = this.$route.query.survey
        await this.handleBotSelected(this.bots[i], false)
        if (aux_survey) {
          let i = null
          this.surveys.some((survey, index) => {
            if (survey._id.toString() === aux_survey) {
              i = index
              return true
            }
          })
          if (i !== null) {
            this.selectedSurvey = this.surveys[i]
            const aux_version = this.$route.query.version
            await this.handleSurveySelected(this.surveys[i], false)
            if (aux_version) {
              let i = null
              this.versions.some((version, index) => {
                if (version._id === aux_version) {
                  i = index
                  return true
                }
              })
              if (i !== null) {
                this.selectedVersion = this.versions[i]
                await this.handleVersionSelected(this.versions[i], false)
              }
            }
          }
        }
      }
    } else if (this.bots.length === 1) {
      await this.handleBotSelected(this.bots[0], false)
      this.$router.push({ query: { bot: this.bots[0]._id } })
    }

    const aux = document.getElementsByClassName('vs-breadcrumb--link')
    for (let i = 0; i < aux.length; i += 1) {
      aux[i].addEventListener('click', (...args) => {
        console.log('click', args)
      })
    }

    if (
      this.selectedSurvey &&
      this.selectedSurvey._id &&
      this.selectedBot &&
      this.selectedBot._id
    ) {
      this.joinScheduleSurveyProgress(
        this.selectedSurvey._id,
        this.selectedBot._id
      )
    }

    this.$vs.loading.close()
  }
}
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
#survey-data-view {
  .vx-card__body {
    padding: 0 30px 30px;
  }
}
</style>
